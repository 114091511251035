import React, { Component } from 'react'
import '../football.css';
import axios from 'axios';
import Select from 'react-select';
import { dbURLists, dbURLPlayerLists, URLSoFIFA, customStyles2 } from '../Constants';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import {DebounceInput} from 'react-debounce-input';
import { Link } from 'react-router-dom';
import withRouter from '../withRouter';

class PlayerLists extends Component {

    state = {
        rowList: [],
        rowAllData: [],
        selectedOptionClub: null,
        selectedOptionNationality: null,
        selectedOptionAge: null,
        SearchString: '',
        loading1: true,
        loading2: true,
      }

    getListItems(){
        axios.get(dbURLists + this.props.params.slug)
            .then(response => {
            this.setState({
                rowList: response.data,
                loading1: false
            });
          });
        } 

    getAllPlayerItems(){
        axios.get(dbURLPlayerLists + this.props.params.slug)
          .then(response => {
            this.setState({
              rowAllData: response.data,
              loading2: false
            });
          });
      } 
    
    componentDidMount() {
        this.getListItems()
        this.getAllPlayerItems()
      }

    handleSearchChange = this.handleSearchChange.bind(this);

    // eslint-disable-next-line 
    handleSearchChange(event) {
        this.setState({SearchString: event.target.value});
        } 
    
    clearSearchField(){
        this.setState({
          SearchString: '', 
            })
        }
    
    selectedOptionClub = (selectedClub) => {
        this.setState({ selectedOptionClub: selectedClub });
    }

    selectedOptionNationality = (selectedNationality) => {
        this.setState({ selectedOptionNationality: selectedNationality });
    } 

    selectedOptionAge = (selectedAge) => {
        this.setState({ selectedOptionAge: selectedAge });
    } 


    clearFilterClub(){
        this.setState({
            selectedOptionClub: null, 
            })
        }
    
    clearFilterNationality(){
        this.setState({
            selectedOptionNationality: null, 
            })
        }
    
    clearFilterAge(){
        this.setState({
            selectedOptionAge: null, 
            })
        }

     scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    scrollToBottom = () => {
        scroll.scrollToBottom();
      };
      
    render() {

        if (this.state.loading1 || this.state.loading2) {
            return <div>
                    <main className="my-4 py-4"><div className="container"> 
                    <div className="row pt-5"><div className="col">
                    <h4>Loading...</h4></div></div></div></main>;
                </div>
        }
        
        const { selectedOptionClub } = this.state;
        const { selectedOptionNationality } = this.state;
        const { selectedOptionAge } = this.state;
        const { SearchString } = this.state;
        const ListsItem = this.state.rowList;
        const AllPlayers = this.state.rowAllData;
        const {sort_decending} = ListsItem[0];
        //console.log(AllPlayers);
        if (AllPlayers.length < 1) {
            return <div>
                        <main className="my-1 py-1">
                            <div className="container">  
                                <div className="row pt-1">
                                    <div className="col">
                                        <h1>404 - Not Found!</h1>
                                        <Link to="/">
                                            Go to current Lists
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </main>
                </div> 
        }
        
        let ListPic, ListPic2, sortedPlayers, filterArray


        if (sort_decending) {
            sortedPlayers = AllPlayers.sort((a, b) => {
                return b.rank - a.rank;
            });
        }
        else {
            sortedPlayers = AllPlayers;
        }
        

        function SearchPlayers(searchText) { 
            let searchArray = [];
            
            for (let i = 0; i < sortedPlayers.length; i++) {

                if (sortedPlayers[i].searchplayer.includes(searchText))
                
                    {searchArray.push(sortedPlayers[i]);}
                    //console.table(filterArrayYear);
            } 
            //console.log(searchArray);
            return searchArray
        }

        

        if (!selectedOptionClub && !selectedOptionNationality && !selectedOptionAge && SearchString) {
            filterArray = SearchPlayers(SearchString.toLowerCase());
            }  

        if (selectedOptionClub && !selectedOptionNationality && !selectedOptionAge) {
            filterArray = sortedPlayers.filter(e => e.current_club === selectedOptionClub.value);
            } 
        if (selectedOptionNationality && !selectedOptionClub && !selectedOptionAge) {
            filterArray = sortedPlayers.filter(e => e.nationality === selectedOptionNationality.value);
            }
        if (selectedOptionAge && !selectedOptionNationality && !selectedOptionClub) {
            filterArray = sortedPlayers.filter(e => e.age === selectedOptionAge.value);
            }
        

        if (!selectedOptionClub && !selectedOptionNationality && !selectedOptionAge && !SearchString) {
            filterArray = sortedPlayers;
            } 

        //console.table(filterArray)

         //get only club
         const OnlyClub = AllPlayers.map(item => item.current_club);
         //unique club + count
         const uniqueClub = OnlyClub.reduce((b,c) => (
             (b[b.findIndex(d => d.current_club===c)] ||
             // eslint-disable-next-line 
             b[b.push({current_club: c, count: 0})-1]).count++,b), 
             []
         );
         const uniqueClubSorted = uniqueClub.sort(function(a, b){
            return b.count-a.count
        })
         const ClubOptions = uniqueClubSorted.map(value => { 
             return {value: value.current_club, label: value.current_club + ' (' + value.count + ')'}; 
         });
 
 
        const OnlyNationality = AllPlayers.map(item => item.nationality);
         const OnlyNationalitySorted = OnlyNationality.sort();
         const uniqueNationality = OnlyNationalitySorted.reduce((b,c) => (
             (b[b.findIndex(d => d.nationality===c)] ||
             // eslint-disable-next-line 
             b[b.push({nationality: c, count: 0})-1]).count++,b), 
             []
         );
         const uniqueNationalitySorted = uniqueNationality.sort(function(a, b){
            return b.count-a.count
        })
         const NationalityOptions = uniqueNationalitySorted.map(value => { 
             return {value: value.nationality, label: value.nationality + ' (' + value.count + ')'}; 
         }); 


         const OnlyAge = AllPlayers.map(item => item.age);
         const OnlyAgeSorted = OnlyAge.sort();
         const uniqueAge = OnlyAgeSorted.reduce((b,c) => (
             (b[b.findIndex(d => d.age===c)] ||
             // eslint-disable-next-line 
             b[b.push({age: c, count: 0})-1]).count++,b), 
             []
         );
         const uniqueAgeSorted = uniqueAge.sort(function(a, b){
            if (a.count === b.count) {
            return b.age - a.age
            }
            return b.count > a.count ? 1 : -1; 
        })
        
         const AgeOptions = uniqueAgeSorted.map(value => { 
             return {value: value.age, label: value.age + ' (' + value.count + ')'}; 
         }); 
        
        const SelectedListHeading = ListsItem.map(value => {

            ListPic = value.picture_link
            ListPic2 = value.picture2

            return (
                <React.Fragment key={value.id}>
                    <div>
                        <h1>{value.name}</h1>

                        <div className="row">
                        
                            {/* kolonne 1 */}
                            <div className="col-sm-3 mb-3">
                                
                                <img src={ListPic}
                                alt="alt" className="img-fluid" /> 

                                {
                                    (() => {
                                    if (ListPic2) {
                                    return  <div className='mt-2'><img src={ListPic2} alt="alt" className="img-fluid" /> </div>
                                    }
                                    return 
                                    })()
                                }

                                
                                {((window.location.hostname === "localhost") || (value.in_progress)) &&
                                <div>
                                    <br />
                                    <div className="alert-sm alert-success text-center mb-1"><b>In progress</b></div> 
                                    
                                    <br />
                                    <div className="text-center">
                                        <div className="btn btn-info btn-xs">total count: {AllPlayers.length}</div>
                                    </div> 
                                </div>
                                }
                                
                            </div>
                            
                            {/* kolonne 2 */}
                            <div className="col-sm-5">
                                
                                <div className="DescriptionBig my-2 mx-2 py-1 px-1">{value.description}</div>
                                <br />

                                <div>

                                    {
                                        (() => {
                                        if (value.url) {
                                        return  <div>
                                                <b>Source</b>
                                                <div className="Description">
                                                    <ul>
                                                        <li>
                                                            <b><a href={value.url}>
                                                                {value.name} ({value.source})
                                                            </a></b>
                                                        </li>
                                                        
                                                    </ul>
                                                </div>
                                        </div>
                                        }
                                        return 
                                        })()
                                    }

                                     <b>Check out</b>
                                    <div className="Description">
                                        <ul>
                                            {
                                                (() => {
                                                if (value.link1 && value.link2 && value.link3 && value.link4) {
                                                    return  <div><li><b><a href={value.url1}>
                                                                {value.link1}
                                                            </a></b>
                                                            </li>
                                                            <li><b><a href={value.url2}>
                                                                {value.link2}
                                                            </a></b>
                                                            </li>
                                                            <li><b><a href={value.url3}>
                                                                {value.link3}
                                                            </a></b>
                                                            </li>
                                                            <li><b><a href={value.url4}>
                                                                {value.link4}
                                                            </a></b>
                                                            </li>
                                                            </div>
                                                    }
                                                if (value.link1 && value.link2 && value.link3) {
                                                    return  <div><li><b><a href={value.url1}>
                                                                {value.link1}
                                                            </a></b>
                                                            </li>
                                                            <li><b><a href={value.url2}>
                                                                {value.link2}
                                                            </a></b>
                                                            </li>
                                                            <li><b><a href={value.url3}>
                                                                {value.link3}
                                                            </a></b>
                                                            </li>
                                                            </div>
                                                    }
                                                if (value.link1 && value.link2) {
                                                return  <div><li><b><a href={value.url1}>
                                                            {value.link1}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url2}>
                                                            {value.link2}
                                                        </a></b>
                                                        </li>
                                                        </div>
                                                }
                                                return 
                                                })()
                                            }
                                            
                                        </ul>
                                    </div> 

                                </div>
                            
                            </div>

                            {/* kolonne 3 */}
                            <div className="col-sm-4 mb-1">
                                {(!this.state.SearchString && !value.no_filters)  &&
                                <div className="row">   
                                    <div className="col">
                                        <h4>Filters</h4>
                                    </div>
                                </div>
                                }
                            
                                {(!this.state.SearchString && !selectedOptionClub && !selectedOptionAge)  &&
                                <div className="row pb-2">  
                                    <div className="col-9">
                                        <Select
                                            className="select"
                                            value={selectedOptionNationality}
                                            onChange={this.selectedOptionNationality}
                                            options={NationalityOptions}
                                            placeholder='Select Nationality'
                                            styles={customStyles2}
                                        />
                                    </div>
                                    <div className="col-3 pl-2">
                                        <button  className="btn btn-primary btn-sm"  onClick={() => this.clearFilterNationality()} >Reset</button>
                                    </div>
                                </div>
                                }

                                {(!this.state.SearchString && !selectedOptionNationality && !selectedOptionAge && value.club_filter)  &&
                                <div className="row pb-2">  
                                    <div className="col-9">
                                        <Select
                                            className="select"
                                            value={selectedOptionClub}
                                            onChange={this.selectedOptionClub}
                                            options={ClubOptions}
                                            placeholder='Select Club'
                                            styles={customStyles2}
                                        />
                                    </div>
                                    <div className="col-3 pl-2">
                                        <button  className="btn btn-primary btn-sm"  onClick={() => this.clearFilterClub()} >Reset</button>
                                    </div>
                                </div>
                                }

                                {(!this.state.SearchString && !selectedOptionNationality && !selectedOptionClub && value.age_filter)  &&
                                <div className="row pb-2">  
                                    <div className="col-9">
                                        <Select
                                            className="select"
                                            value={selectedOptionAge}
                                            onChange={this.selectedOptionAge}
                                            options={AgeOptions}
                                            placeholder='Select Age'
                                            styles={customStyles2}
                                        />
                                    </div>
                                    <div className="col-3 pl-2">
                                        <button  className="btn btn-primary btn-sm"  onClick={() => this.clearFilterAge()} >Reset</button>
                                    </div>
                                </div>
                                }

                                {((!this.state.selectedOptionNationality) && (!this.state.selectedOptionClub) && (!this.state.selectedOptionAge))  && 
                                <div className="row">   
                                        <div className="col">
                                        <h4>Search</h4>
                                    </div>
                                </div>
                                }

                                {((!this.state.selectedOptionClub) && (!this.state.selectedOptionNationality) && (!this.state.selectedOptionAge))  && 
                                    <div className="row pb-2">  
                                            <div className="col-9">
                                    
                                            <div className="search">    
                                                    <DebounceInput
                                                        //type="number"
                                                        minLength={2}
                                                        debounceTimeout={700}
                                                        value={SearchString}
                                                        onChange={this.handleSearchChange}
                                                        placeholder="Search all players" 
                                                    />
                                                </div>

                                            </div>
                                            <div className="col-3 pl-0">
                                                <button type="button" className="btn btn-primary"  
                                                onClick={() => this.clearSearchField()} >Reset</button>
                                            </div>
                                    </div>
                                }

                    
                            </div>

                        </div>
                        <div className="row">
                            <div className="col pb-2" align="Right"> 
                                <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToBottom}>
                                <b>Scroll down</b>
                                </button>
                            </div>
                        </div>
                        
                    </div>

                </React.Fragment>
                )
            }
        )

        

        const PlayerItems = filterArray.map(value => {


            return (
                <React.Fragment key={value.key_id}>
    
                    <div className="row pt-3 pb-3 border-bottom border-top border-info">
                    
                                {/* 1 kolonne */}
                                <div className="col-sm-2 text-center">
                                    <div className='Title16 mb-1 px-1'><b>#{value.rank} - {value.player_name}</b></div> 

                                    <div align="center">
                                        {
                                            (() => {
                                            if (value.picture_url) {
                                            return  <img src={value.picture_url} alt=""
                                            className="img-fluid"></img>
                                            }
                                            return 
                                            })()
                                        }
                                        <div className="Title16 px-1 my-2"><b>{value.current_club}</b></div>
  
                                        
                                    </div>

                                </div>

                                {/* 2 kolonne */}
                                <div className="col-sm-5 pb-1 border-right Description">

                                    <div className="row">
                                        <div className="col py-2 border-right Description">

                                            {
                                            (() => {
                                            if (value.description) {
                                            return  <div>
                                                        <ReadMoreAndLess
                                                                charLimit={850}
                                                                readMoreText={" Read more ▼"}
                                                                readLessText={" Read less ▲"}
                                                                readMoreClassName="read-more-less--more"
                                                                readLessClassName="read-more-less--less"
                                                            >
                                                            {value.description}
                                                        </ReadMoreAndLess>
   
                                                </div>
                                            }
                                            if (!value.description && value.comment) {
                                                return <div className="card bg-light py-2 px-2">
                                                            <p className="quote3">{value.comment}</p>
                                                                {
                                                                    (() => {
                                                                            if (value.date_txt) {
                                                                                return <div className="blockquote-footer"><i>{value.people_txt} ({value.date_txt})</i></div>
                                                                            }
                                                                            return <div className="blockquote-footer"><i>{value.people_txt}</i></div>

                                                                    })()

                                                                }
                                                    </div>
                                                }
                                                return 
                                                })()
                                            }
                                            
                                            {
                                                (() => {
                                                if (value.wikipedia) {
                                                return  <div className='Description mt-2'>
                                                            <em> -> <a href={value.wikipedia}>more info. @ wikipedia</a></em>
                                                    </div>
                                                }
                                                return 
                                                })()
                                            }
                                            {
                                                (() => {
                                                if (value.sofifa_id) {
                                                return  <div className='Description mt-2'>
                                                            <em> -> <a href={URLSoFIFA + value.sofifa_id}>SoFIFA profile</a></em>
                                                    </div>
                                                }
                                                return 
                                                })()
                                            }
                                        </div>

                                    </div>

                                        

                                                        
                                        
                                </div>               
                                
                                    
                                {/* 3 kolonne */}
                                <div className="col-sm-5">

                                    <div className="row">
                                    
                                        <div className="col-sm-5">
                                                <div>
                                                    {
                                                        (() => {
                                                            if (!value.dead) {
                                                                return <div><b>Date of birth </b><br />
                                                                            <div className="Date py-1 px-2">{value.born}&nbsp;
                                                                            (age {value.age})
                                                                            </div>
                                                                        </div>
                                                                }
                                                            return <div><b>Date of birth </b><br />
                                                                        <div className="Date py-1 px-2">{value.born}
                                                                        </div>
                                                                        <b>Date of death</b>
                                                                        <div className="Date py-1 px-2">{value.dead}&nbsp;
                                                                            (aged {value.dead_age})
                                                                        </div>
                                                                    </div>
                                                        })()
                                                    }
                                                
                                                    

                                                    <div><b>Nationality </b><br />
                                                        <div className="Date py-1 px-2">{value.nationality}
                                                        </div>
                                                    </div>

                                                    {
                                                        (() => {
                                                            if (value.heigth) {
                                                                return <div><b>Height </b><br />
                                                                            <div className="Date py-1 px-2">{value.heigth} cm</div>
                                                                        </div>
                                                                }
                                                    
                                                        })()
                                                    }

                                                    <div><b>Position(s) </b><br />
                                                        <div className="Date py-1 px-2">{value.position}</div>
                                                    </div>

                                                    <div><b>International caps </b><br />
                                                        <div className="Date py-1 px-2">{value.national_app}</div>
                                                    </div>
                                                    <div className="SmallText-10 mt-2">
                                                        facts updated -> {value.updated_txt} </div>
  
                                                </div>

                                        </div>

                                        <div className="col-sm-7 mt-3">
                                                
                                                
                                            {
                                                (() => {
                                                    if (value.comment && !value.comment2) {
                                                        return <div className="card bg-light p-2 mb-2">
                                                                    <p className="quote15">{value.comment}</p> 
                                                                    <div className="blockquote-footer"><i>{value.source}</i></div>
                                                                    
                                                                </div>
                                                        }
                                            
                                                })()
                                            }

                                            {
                                                (() => {
                                                    if (value.comment && value.comment2) {
                                                        return <div>
                                                                    <div className="card bg-light p-2 mb-2">
                                                                        <p className="quote15">{value.comment}</p> 
                                                                        <div className="blockquote-footer"><i>{value.source}</i></div>
                                                                        
                                                                    </div>
                                                                    <div className="card bg-light p-2 mb-2">
                                                                        <p className="quote15">{value.comment2}</p> 
                                                                        <div className="blockquote-footer"><i>{value.source2}</i></div>
                                                                        
                                                                    </div>
                                                                </div>
                                                        }
                                            
                                                })()
                                            }   
                                                

                                                

                                        
                                        </div>

                                    </div>

                                    {/* <div className="row">
                                        <div className="blockquote-source-11 mt-4">facts updated -> {value.updated_txt} </div>
                                    </div> */}
                                
                                    
                                
                                </div>
                                    

                    </div>
                           

                </React.Fragment>          
            )

        })

        

    
        return (

            <main className="my-1 py-1">
                    <div className="container">
                          
                        { SelectedListHeading }

                        
    
                        {
                            (() => {
                            if (this.state.loading) {
                                return <div><br /><h5><i>Loading...</i></h5></div>
                            }
                            return <div> 
                                    <div>
                                        <div className="row">
                                            <div className="col">
                                                    {PlayerItems}
                                            </div>
                                        </div>
                                       
                                        
                                    </div>
                                
                                </div>
                            })()
                        } 
                        
                        
                        
                        
    
                        <p align="Right"><br />
                            <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                            <b>Scroll to top</b></button>
                        </p>
                  
                    </div>
                </main>
            
        )
    }
}

export default withRouter(PlayerLists);