import React, { Component } from 'react'
import '../football.css';
import axios from 'axios';
//import Select from 'react-select';
import { dbURLPlayersToday, URLSoFIFA } from '../Constants';
import { animateScroll as scroll } from "react-scroll";
import ReadMoreAndLess from "react-read-more-less";
import { Link } from 'react-router-dom';
//import withRouter from '../withRouter';

class PlayersToday extends Component {

    state = {
        rowAllPlayerData: [],
        loading1: true
      }

    getAllPlayerItems(){
        axios.get(dbURLPlayersToday)
          .then(response => {
            this.setState({
              rowAllPlayerData: response.data,
              loading1: false
            });
          });
      } 
    
    componentDidMount() {
        this.getAllPlayerItems()
      }
    

     scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    scrollToBottom = () => {
        scroll.scrollToBottom();
      };
      
    render() {
        //console.log(dbURLPlayersTodayHeading);

        if (this.state.loading1) {
            return <div>
                    <main className="my-4 py-4"><div className="container"> 
                    <div className="row pt-5"><div className="col">
                    <h4>Loading...</h4></div></div></div></main>;
                </div>
        }

        const AllPlayers = this.state.rowAllPlayerData;
        const {today} = AllPlayers[0];

        //console.log(ListsItem);
        if (AllPlayers.length < 1) {
            return <div>
                        <main className="my-1 py-1">
                            <div className="container">  
                                <div className="row pt-1">
                                    <div className="col">
                                        <h1>404 - Not Found!</h1>
                                        <Link to="/">
                                            Go to current Lists
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </main>
                </div> 
        }

        const FirstIndexEmpty = AllPlayers.findIndex((player) => player.name==="");
        //console.log(FirstIndexNull);
        

        const PlayerItems = AllPlayers.map((value, index) => {


            return (
                <React.Fragment key={value.id}>
    
                    
                        {
                            (() => {
                            if (value.name) {
                            return  <div>
                                        {
                                            (() => {
                                            if (index === 0) {
                                            return  <div className='center mt-2'>
                                                        <h3>Featured players</h3>
                                                </div>
                                            }
                                            return 
                                            })()
                                        }
                                        <div className="row pt-3 pb-3 border-top border-info">
                                            {/* 1 kolonne */}
                                            <div className="col-sm-2 text-center">
                                                

                                                <div align="center">
                                                    {
                                                        (() => {
                                                        if (value.picture_url) {
                                                        return  <img src={value.picture_url} alt=""
                                                        className="img-fluid"></img>
                                                        }
                                                        return 
                                                        })()
                                                    }
                                                    {/* <div className="Title16 px-1 my-2"><b>{value.current_club}</b></div> */}
            
                                                    
                                                </div>

                                            </div>

                                            {/* 2 kolonne */}
                                            <div className="col-sm-5 mt-1 pb-1 border-right Description">

                                                <div className="row">
                                                    <div className="col border-right Description">
                                                        {
                                                            (() => {
                                                                    if (value.dead) {
                                                                        return <div className='Title16 mb-1 px-1'><b>Remembering {value.name}{"\n"}{value.born} - {value.dead}</b></div> 
                                                                    }
                                                                    if (value.current_club) {
                                                                    return <div className='Title16 mb-1 px-1'><b>{value.name} (age {value.age}) - {value.current_club}</b></div> 
                                                                    }
                                                                    return <div className='Title16 mb-1 px-1'><b>{value.name} (age {value.age}) - <i>Retired</i></b></div> 
                                                            })()

                                                        }

                                                        {
                                                        (() => {
                                                        if (value.description) {
                                                        return  <div>
                                                                    <ReadMoreAndLess
                                                                            charLimit={600}
                                                                            readMoreText={" Read more ▼"}
                                                                            readLessText={" Read less ▲"}
                                                                            readMoreClassName="read-more-less--more"
                                                                            readLessClassName="read-more-less--less"
                                                                        >
                                                                        {value.description}
                                                                    </ReadMoreAndLess>
            
                                                            </div>
                                                        }
                                                        if (!value.description && value.comment) {
                                                            return <div className="card bg-light py-2 px-2">
                                                                        <p className="quote3">{value.comment}</p>
                                                                            {
                                                                                (() => {
                                                                                        if (value.date_txt) {
                                                                                            return <div className="blockquote-footer"><i>{value.people_txt} ({value.date_txt})</i></div>
                                                                                        }
                                                                                        return <div className="blockquote-footer"><i>{value.people_txt}</i></div>

                                                                                })()

                                                                            }
                                                                </div>
                                                            }
                                                            return 
                                                            })()
                                                        }
                                                        
                                                        {
                                                            (() => {
                                                            if (value.wikipedia) {
                                                            return  <div className='Description mt-2'>
                                                                        <em> -> <a href={value.wikipedia}>more info. @ wikipedia</a></em>
                                                                </div>
                                                            }
                                                            return 
                                                            })()
                                                        }
                                                        {
                                                            (() => {
                                                            if (value.sofifa_id) {
                                                            return  <div className='Description mt-2'>
                                                                        <em> -> <a href={URLSoFIFA + value.sofifa_id}>SoFIFA profile</a></em>
                                                                </div>
                                                            }
                                                            return 
                                                            })()
                                                        }
                                                    </div>

                                                </div>                    
                                                    
                                            </div>               
                                                
                                            {/* 3 kolonne */}
                                            <div className="col-sm-5">

                                                <div className="row">
                                                
                                                    <div className="col-sm-5">
                                                            <div>
                                                                {
                                                                    (() => {
                                                                            if (!value.dead) {
                                                                                return <div>
                                                                                            <div><b>Date of birth </b><br />
                                                                                                <div className="Date py-1 px-2">{value.born}
                                                                                                </div>
                                                                                            </div>    
                                                                                    </div>
                                                                            }
                                                                            return
                                                                    })()

                                                                }


                                                                    

                                                                {
                                                                    (() => {
                                                                            if (!value.current_club) {
                                                                                return <div>
                                                                                            <div>
                                                                                                <div><b>Main club </b><br />
                                                                                                    <div className="Date py-1 px-2">{value.main_club}
                                                                                                    </div>
                                                                                                </div>
                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                            return
                                                                    })()

                                                                }
                                                            
                                                               


                                                                <div><b>Nationality </b><br />
                                                                    <div className="Date py-1 px-2">{value.nationality}
                                                                    </div>
                                                                </div>

                                                                <div><b>Height </b><br />
                                                                    <div className="Date py-1 px-2">{value.heigth} cm</div>
                                                                </div>

                                                                <div><b>Position(s) </b><br />
                                                                    <div className="Date py-1 px-2">{value.position}</div>
                                                                </div>

                                                                {
                                                                    (() => {
                                                                    if (value.national_app) {
                                                                    return  <div><b>International caps </b><br />
                                                                                <div className="Date py-1 px-2">{value.national_app}</div>
                                                                            </div>
                                                                    }
                                                                    return 
                                                                    })()
                                                                }

                                                                
                                                                <div className="SmallText-10 mt-2">
                                                                    facts updated -> {value.updated_txt} </div>
            
                                                            </div>

                                                    </div>

                                                    <div className="col-sm-7 mt-3">
                                                            
                                                            
                                                        {
                                                            (() => {
                                                                if (value.comment) {
                                                                    return <div className="card bg-light p-2 mb-2">
                                                                                <p className="quote15">{value.comment}</p> 
                                                                                <div className="blockquote-footer"><i>{value.source}</i></div>
                                                                                
                                                                            </div>
                                                                    }
                                                        
                                                            })()
                                                        }

                                                    
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                </div>
                            }
                            return <div>
                                        {
                                            (() => {
                                            if (index === FirstIndexEmpty) {
                                            return  <div className='center border-top pt-2 mt-5'>
                                                        <h3>Other players</h3>
                                                        <div className="SmallText-10 my-2">
                                                    club info. updated -> {value.updated_txt} </div>
                                                </div>
                                            }
                                            return 
                                            })()
                                        }
                                        <div className="row py-2 border-top border-info">
                                            {/* 1 kolonne */}
                                            <div className="col-sm-2 text-center">
                                                    <div className='Title2 mb-1 px-1'>
                                                        <b>{value.short_name}</b></div> 
                                                    {
                                                        (() => {
                                                        if (value.sofifa_id) {
                                                        return  <div className='Description mt-2'>
                                                                    <em> -> <a href={URLSoFIFA + value.sofifa_id}>SoFIFA profile</a></em>
                                                            </div>
                                                        }
                                                        return 
                                                        })()
                                                    }


                                                </div>
                                            {/* 2 kolonne */}
                                            <div className="col-sm-3 pb-1 border-right Description">

                                                <div className="row">
                                                    <div className="col border-right Description">
                                                        <div><b>Date of birth </b> {value.born}&nbsp;(age {value.age})
                                                        </div>
                                                        <div><b>Club </b> {value.current_club}
                                                        </div>
                                                        
                                                       
                                                    </div>

                                                </div>                    
                                                    
                                            </div> 
                                            {/* 3 kolonne */}
                                            <div className="col-sm-7">
                                                <div className="Description"><b>Nationality </b> {value.nationality}
                                                </div>
                                                <div className="Description"><b>Height </b> {value.heigth} cm
                                                </div>

                                                <div className="Description"><b>Position(s) </b> {value.position}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                            })()
                        }          
                           

                </React.Fragment>          
            )

        })

        

    
        return (

            <main className="my-1 py-1">
                    <div className="container">
                          
    
                        {
                            (() => {
                            if (this.state.loading) {
                                return <div><br /><h5><i>Loading...</i></h5></div>
                            }
                            return <div>
                                       
                                        <div className="row">
                                            <div className="col-sm-11">
                                                <div class="alert alert-secondary mx-3" role="alert">
                                                    <h4>Also check out:</h4>
                                                    <ul>
                                                        <li>
                                                            <b><a href='/lists/players/the-100-best-male-footballers-in-the-world-2022'>
                                                            The 100 best male footballers in the world, 2023 (The Guardian)
                                                            </a></b>
                                                        </li>
                                                        <li>
                                                            <b><a href='/lists/players/ranked-the-100-best-players-in-the-world-2022'>
                                                            Ranked! The 100 best players in the world, 2022 (FourFourTwo)
                                                            </a></b>
                                                        </li>
                                                         <li>
                                                            <b><a href='/lists/players/greatest-players-of-the-20th-century'>
                                                            Greatest Players of the 20th century, 2023 (World Soccer)
                                                            </a></b>
                                                        </li> 
                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row pt-1">
                                            <div className="col-sm-11">
                                                <div className="Header p-2 mx-3">
                                                    Football players born / dead on {today}
                                                </div>
                                            </div>
                                        </div>

                                        

                                        {PlayerItems}

                                    </div>
                            })()
                        } 
                        

                        <p align="Right"><br />
                            <button type="button" className="btn btn-primary btn-sm" onClick={this.scrollToTop}>
                            <b>Scroll to top</b></button>
                        </p>
                  
                    </div>
                </main>
            
        )
    }
}

export default PlayersToday;