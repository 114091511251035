import React from 'react';
import { Route, Navigate } from 'react-router-dom';

const NotFound = () => {

    return (

        
    <div>
        {/* REROUTING */}
        <Route exact path="/">
            <Navigate to="/lists/players/ranked-the-100-best-players-in-the-world-2022" />
        </Route>
        
        

        <main className="my-1 py-1">
            <div className="container">  
                <div className="row pt-1">
                    <div className="col">
                        <h1>404 - Not Found!</h1>
                        
                    </div>
                </div>
            </div>
        </main>
    </div>
    );
};

export default NotFound;

