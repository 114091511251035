export const dbURL = "https://api2204.musicthisday.com/";
export const dbURLists = "https://api2204.musicthisday.com/listsplayers/";
export const dbURLPlayerLists = "https://api2204.musicthisday.com/listplayers/";
export const dbURLPlayersToday = "https://api2204.musicthisday.com/footballplayerstoday";
export const dbURLPlayersTodayHeading = "https://api2204.musicthisday.com/listsplayers/today";
export const dbURLManUPlayers = "https://api2204.musicthisday.com/listsmanuplayers";
export const URLSoFIFA = "https://sofifa.com/player/";

export const dbURLlocal = "http://localhost:3005/";

export const customStyles2 = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '25px',
      width: '265px',
      boxShadow: state.isFocused ? null : null,
    }),

     container: (provided, state) => ({
        ...provided,
        height: '35px',
        width: '250px',
        //padding: '0 6px'
      }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      //width: '150px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      //width: '150px',
    }),
  };