
import React from 'react';
//import { Link } from "react-router-dom";
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';

const Header = () => (

    <Navbar bg="light" expand="lg">
        <Container>
            <Navbar.Brand href="/">
                    <img
                    alt=""
                    src="/images/football.jpg"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    />{' '}
                FootballThisDay
             </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                 <NavDropdown title="Lists" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/lists/players/ranked-the-100-best-players-in-the-world-2022">Ranked! The 100 best players in the world, 2022 (FourFourTwo)</NavDropdown.Item>
                    <NavDropdown.Item href="/lists/players/the-100-best-male-footballers-in-the-world-2022">The 100 best male footballers in the world, 2023 (The Guardian)</NavDropdown.Item>
                    <NavDropdown.Item href="/lists/players/greatest-players-of-the-20th-century">Greatest Players of the 20th century, 2023 (World Soccer)</NavDropdown.Item>
                </NavDropdown> 
            </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
    
)

export default Header;