import React, { Component } from "react";
import Header from './components/Header';
//import Home from "./components/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import NotFound from "./NotFound";
import PlayerLists from "./components/players/PlayersLists";
import PlayersToday from "./components/players/PlayersToday";
import ManuPlayers from "./components/players/ManuPlayers";


class App extends Component {
  
  render() {
      return (

        <div className="App">

          <Router>

            <Header />

            <Routes>

              {/* <Route exact path='/' element={<Home/>} /> 
              <Route exact path='/' 
              element={<PlayerLists slug='ranked-the-100-best-players-in-the-world-2022'/>} /> 

            <Route path="/" element={<PlayerLists />} />    */}

            <Route path="/today" sluge='today' element={<PlayersToday />} /> 
            
             <Route path="/" element={<Navigate to="/today" />} />

            <Route path="/lists/players/:slug" element={<PlayerLists />} />     

            <Route path="/lists/manuplayers" element={<ManuPlayers />} />     
          
              
            <Route component={NotFound} />

            </Routes>

          </Router>

        </div>
      )
    }
  }

export default App;